<template>
    <div class="platformContainer">
        <div class="systemSettingsNav">
            <el-menu :default-active="plat_defaultIndex" class="el-menu-demo" mode="horizontal" @select="plat_handleSelect">
                <el-menu-item index="plat_tag1">基础设置</el-menu-item>
                <el-menu-item index="plat_tag2">注册协议</el-menu-item>
                <el-menu-item index="plat_tag3">小票打印配置</el-menu-item>
                <el-menu-item index="plat_tag4">支付设置</el-menu-item>
            </el-menu>
        </div>
        <div class="platformMain" v-if="plat_activeIndex === 'plat_tag1'">
            <el-divider content-position="left" class="plat_title">平台基础配置</el-divider>
            <el-form ref="form" :model="form" label-width="3rem" style="width:40%" size="mini">
                <el-form-item label="商家名称：" prop="merchantName">
                    <el-input v-model="form.merchantName" placeholder="请输入商家名称：" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="商家LOGO：">
                    <uploadImgs :image="form.imageUrl" @custom-event="receiveFromChild" :disabled="disabled"></uploadImgs>
                </el-form-item>
                <el-form-item label="联系方式：" prop="telephone">
                    <el-input v-model="form.telephone" placeholder="请输入联系方式：" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="联系地址：" prop="address">
                    <el-input v-model="form.address" placeholder="请输入联系地址" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="金额小数点位数设置：" prop="digit" v-if="false">
                    <el-select placeholder="请选择金额小数点位数设置" v-model="form.digit" :disabled="disabled">
                        <el-option label="取整" :value="1"></el-option>
                        <el-option label="1位数" :value="2"></el-option>
                        <el-option label="2位数" :value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户自费储值余额最低：" prop="minimumBalance">
                    <el-input v-model="form.minimumBalance" placeholder="请输入客户自费储值余额最低" :disabled="disabled"></el-input>
                    <span style="color: #8a8a8a;">默认0不限制用户最低余额，低于此余额将有提示和无法使用</span>
                </el-form-item>
                <el-form-item label="客户端地址：" prop="webUrl" :rules="[
                    { required: true, message: '客户端地址不能为空', trigger: 'blur' }
                ]">
                    <el-input v-model="form.webUrl" placeholder="请输入客户端地址" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="后端接口访问地址：" prop="apiUrl" :rules="[
                    { required: true, message: '后端接口访问地址不能为空', trigger: 'blur' }
                ]">
                    <el-input v-model="form.apiUrl" placeholder="请输入后端接口访问地址" :disabled="disabled"></el-input>
                </el-form-item>
                <el-form-item label="手机端访问地址：" prop="accountUrl" :rules="[
                    { required: true, message: '手机端访问地址不能为空', trigger: 'blur' }
                ]">
                    <el-input v-model="form.accountUrl" placeholder="请输入手机端访问地址" :disabled="disabled"></el-input>
                </el-form-item>
            </el-form>
            <div class="saveStyle">
                <el-button type="primary" size="mini" @click="_configBasicConfiguration" :disabled="disabled">保存</el-button>
            </div>
        </div>
        <div class="platformMain" v-if="plat_activeIndex === 'plat_tag2'">
            <el-divider content-position="left" class="plat_title">会员规则说明</el-divider>
            <el-form ref="form1" :model="form1" label-width="160px" style="width:40%" size="mini">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="会员规则说明：">
                            <el-radio-group v-model="form1.clientRuleStatus">
                                <el-radio :label="0">关闭</el-radio>
                                <el-radio :label="1">开启</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item>
                            <el-input placeholder="会员规则" v-model="form1.clientRule"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item>
                            <quill-editor class="editor" v-model="form1.content" ref="customQuillEditor"
                                :options="editorOption">
                            </quill-editor>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="saveStyle">
                <el-button type="primary" size="mini" @click="_configRegistrationAgreement">保存</el-button>
            </div>
        </div>
        <div class="platformMain" v-if="plat_activeIndex === 'plat_tag3'">
            <el-divider content-position="left" class="plat_title">小票打印配置</el-divider>
            <el-form ref="form" :model="form" label-width="160px" style="width:30%" size="mini">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="设备名称：">
                            <el-input v-model="form.name" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="appId：">
                            <el-input v-model="form.appId" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="appSecret：">
                            <el-input v-model="form.appSecret" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="equipmentCode：">
                            <el-input v-model="form.equipmentCode" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="equipmentKey：">
                            <el-input v-model="form.equipmentKey" :disabled="disabled"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="status：">
                            <el-tag type="success" size="small" v-if="form.status == '1'">在线</el-tag>
                            <el-tag type="danger" size="small" v-if="form.status == '0'">离线</el-tag>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="saveStyle">
                <el-button type="primary" size="mini" @click="_addPrinterConfig">保存</el-button>
            </div>
        </div>
        <div class="platformMain" v-if="plat_activeIndex === 'plat_tag4'">
            <el-divider content-position="left" class="plat_title">支付设置</el-divider>
            <el-form ref="form4" :model="form4" label-width="2rem" style="width:50%" size="mini" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="微信支付状态：" prop="actived">
                            <el-radio-group v-model="form4.actived">
                                <el-radio :label="1">开启</el-radio>
                                <el-radio :label="0">关闭</el-radio>
                            </el-radio-group>
                            <span class="note" style="margin-left: .25rem;">JSAPI支付授权目录:
                                https://kangyishequ.kangyishequ.com/</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="微信支付模式：" prop="pattern">
                            <el-checkbox v-model="form4.pattern">普通模式</el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="appID" prop="appId">
                            <el-input v-model="form4.appId"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="支付商户号：" prop="mchId">
                            <el-input v-model="form4.mchId"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="支付秘钥：" prop="mchSecret">
                            <el-input v-model="form4.mchSecret"></el-input>
                            <span class="note">请在<span
                                    style="color: rgb(64,64,64);">微信支付商户平台</span>[账户中心]-[API安全]中设置[APIv2密钥]</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="PEM证书：">
                            <el-upload class="upload-demo" action="" multiple :limit="1" :file-list="pemList" name="pemFile"
                                :http-request="pemUpload">
                                <el-button size="small" type="primary" class="upbtn">点击上传</el-button>
                                <div v-if="form4.certPemContent != ''">
                                    <span style="margin-right:.125rem;color:#606266;"><i class="el-icon-document"
                                            style="margin-right:.125rem;"></i>{{ form4.certPemContent }}</span><span
                                        style="color:#409eff;">已上传<i class="el-icon-check"></i></span>
                                </div>
                            </el-upload>
                            <span class="note">apiclient__cert.pem请在<span
                                    style="color: rgb(64,64,64);">微信支付商户平台</span>[账户中心]-[API安全]中设置[API证书]，设置完成后上传</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="证书秘钥：">
                            <el-upload class="upload-demo" action="" multiple :limit="1" :file-list="keyList" name="keyFile"
                                :http-request="pemUpload2">
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div v-if="form4.keyPemContent != ''">
                                    <span style="margin-right:.125rem;color:#606266;"><i class="el-icon-document"
                                            style="margin-right:.125rem;"></i>{{ form4.keyPemContent }}</span><span
                                        style="color:#409eff;">已上传<i class="el-icon-check"></i></span>
                                </div>
                            </el-upload>
                            <span class="note">apiclient__key.pem请在<span
                                    style="color: rgb(64,64,64);">微信支付商户平台</span>[账户中心]-[API安全]中设置[API证书]，设置完成后上传</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="证书序列号：" prop="pkcs12Content">
                            <el-input v-model="form4.pkcs12Content"></el-input>
                            <span class="note">使用商家转账到零钱功能时填写此项，否则不需要填写,请在<span
                                    style="color: rgb(64,64,64);">微信支付商户平台</span>[账户中心]-[API安全]-[管理证书]中查看 </span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="saveStyle">
                <el-button type="primary" size="mini" @click="zfSave()">保存</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { getWechatMch, addPrinterConfig, getPrinterConfig, configWechatMch, getBasicConfiguration, configBasicConfiguration, getRegistrationAgreement, configRegistrationAgreement } from '../../../api/systemOption/platformConfiguration/index'
import { quillEditor } from "vue-quill-editor";
import uploadImgs from '../../components/uploadImg.vue'
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";


// 工具栏配置

const toolbarOptions = [
    ['bold', 'italic'], // 加粗 斜体 下划线 删除线
    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
    [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
    [{ align: [] }], // 对齐方式
    ['clean'], // 清除文本格式
]



export default {
    components: {
        quillEditor,
        uploadImgs
    },
    data() {
        return {
            plat_defaultIndex: 'plat_tag1',
            plat_activeIndex: 'plat_tag1',
            disabled: false,
            form: {},
            form1: {},
            fileList: [],
            filename: '',
            radio: '0',
            editorOption: {
                modules: {
                    toolbar: {
                        container: toolbarOptions, //工具栏相关配置
                    },
                },
            },
            pemList: [],
            keyList: [],
            formData: new FormData(),
            status: '',
            form4: {
                appId: 'wx65c941b5c76cd32d',
                actived: 0,
                pattern: 0,
                mchId: '1671373541',
                mchSecret: '990119',
                certPemContent: '',
                keyPemContent: '',
                pkcs12Content: ''
            },
            rules: {
                appId: [{ required: true, message: '请输入appId', trigger: 'blur' }],
                actived: [{ required: true, message: '请选择微信支付状态', trigger: 'blur' }],
                mchId: [{ required: true, message: '请输入支付商户号', trigger: 'blur' }],
                mchSecret: [{ required: true, message: '请输入支付秘钥', trigger: 'blur' }],
                certPemContentFile: [{ required: true, message: '请上传PEM证书', trigger: 'blur' }],
                keyPemContentFile: [{ required: true, message: '请上传证书秘钥', trigger: 'blur' }],
                // pkcs12Content: [{ required: true, message: '请输入证书序列号', trigger: 'blur' }],
            }
        }
    },
    // 使用
    mounted() {
        const role = sessionStorage.getItem('login')
        if (role != 'admin') {
            this.disabled = true
        }
        this._getBasicConfiguration()
    },
    methods: {
        plat_handleSelect(key, keyPath) {
            this.plat_activeIndex = key
            switch (key) {
                case 'plat_tag1':
                    this._getBasicConfiguration()
                    break;
                case 'plat_tag2':
                    this._getRegistrationAgreement()
                    break;
                case 'plat_tag3':
                    this._getPrinterConfig()
                    break;
                case 'plat_tag4':
                    this._getWechatMch()
                    break;

                default:
                    break;
            }
        },
        _getBasicConfiguration() {
            getBasicConfiguration().then(res => {
                if (res.state == '200') {
                    this.form = res.data
                }
            })
        },
        _configBasicConfiguration() {
            configBasicConfiguration(this.form).then(res => {
                if (res.state == '200') {
                    this.$message.success('保存成功')
                    this._getBasicConfiguration()
                }
            })
        },
        _getRegistrationAgreement() {
            getRegistrationAgreement().then(res => {
                if (res.state == '200') {
                    this.form1 = res.data
                }
            })
        },
        _configRegistrationAgreement() {
            configRegistrationAgreement(this.form1).then(res => {
                if (res.state == '200') {
                    this.$message.success('保存成功')
                    this._getRegistrationAgreement()
                }
            })
        },
        _getPrinterConfig() {
            getPrinterConfig().then(res => {
                if (res.state == '200') {
                    this.form = res.data
                    // if (res.data.status == '0') {
                    //     this.status = '离线'
                    // } else if (res.data.status == '1') {
                    //     this.status = '在线'
                    // }
                }
            })
        },
        _addPrinterConfig() {
            addPrinterConfig(this.form).then(res => {
                if (res.state == '200') {
                    this.$message.success('修改成功')
                    this._getPrinterConfig()
                }
            })
        },
        receiveFromChild(data) {
            console.log(data)
            this.form.imageUrl = data;
        },
        _getWechatMch() {
            getWechatMch().then(res => {
                if (res.state == '200') {
                    const { data } = res
                    if (data && data.toString() != '{}') {
                        if (data.id != '') {
                            this.form4.id = data.id
                        }
                        this.form4 = {
                            appId: data.appId,
                            actived: data.actived,
                            pattern: data.pattern == 1 ? true : false,
                            mchId: data.mchId,
                            mchSecret: data.mchSecret,
                            // 证书文件
                            certPemContent: data.certPemContent,
                            // 证书秘钥
                            keyPemContent: data.keyPemContent,
                            pkcs12Content: data.pkcs12Content
                        }
                    }
                }
            })
        },
        pemUpload(param) {
            this.formData.append('certPemContentFile', param.file)
            this.form4.certPemContent = ''
            for (const iterator of this.formData) {
                console.log('for of--->', iterator);
            }

        },
        pemUpload2(param) {
            this.formData.append('keyPemContentFile', param.file)
            this.form4.keyPemContent = ''
        },
        setFormData(form) {
            const { pattern } = this.form4
            this.form4.pattern = pattern ? 1 : 0
            this.formData.append('appId', this.form4.appId)
            this.formData.append('actived', this.form4.actived)
            this.formData.append('pattern', this.form4.pattern)
            this.formData.append('mchId', this.form4.mchId)
            this.formData.append('mchSecret', this.form4.mchSecret)
            this.formData.append('pkcs12Content', this.form4.pkcs12Content)
            // for (const iterator of this.formData) {
            //     console.log('for of--->', iterator);
            // }

        },
        refreshFormate() {
            this.formData = null
            this.formData = new FormData()
        },
        zfSave() {
            // console.log(this.formData);
            for (const iterator of this.formData) {
                console.log('for of--->', iterator);
            }
            this.$refs.form4.validate(valid => {
                if (valid) {
                    this.setFormData()
                    configWechatMch(this.formData).then(res => {
                        if (res.state == '200') {
                            this.$message.success('保存成功')
                            this._getWechatMch()
                            this.refreshFormate()
                        } else {
                            this.refreshFormate()
                        }
                    })
                }
            })
        },

    },

}
</script>
<style scoped>
::v-deep .el-upload.el-upload--text {
    /* border: none !important; */
    border-width: 0 !important;
    text-align: left !important;
}

.platformContainer {
    width: 100%;
    /* height: 100%; */
    flex: 1;
    display: flex;
    flex-direction: column;
}

.platformMain {
    background-color: #fff;
    width: 100%;
    padding: .125rem .25rem;
    flex: 1;
    margin-top: .25rem;
}

/*上传图片样式*/
.avatar-uploader-icon {
    font-size: .35rem;
    color: #8c939d;
    width: .875rem;
    height: .875rem;
    line-height: .875rem;
    text-align: center;
}

.el-upload {
    border: none;
}

.el-upload__tip {
    width: .875rem;
    /* text-align: center; */
}

.saveStyle {
    margin-top: .5rem;
    width: 40%;
    text-align: center;
}

.ql-container {
    height: 3.75rem;
}

.note {
    font-size: .15rem;
    color: rgb(124, 136, 169);
}
</style>